window._ = require("lodash");
import "bootstrap";

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


/* ================================ */
/* ===== Import JS libraries ====== */
/* ================================ */

/**
 * jQuery
 */
window.$ = window.jQuery = require('jquery');

/**
 * Additional jQuery libraries
 */
import 'magnific-popup/dist/jquery.magnific-popup.min.js';

/**
 * Adyen JS
 */
import AdyenWeb from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
window.AdyenCheckout = AdyenWeb;
/* ================================ */
/* === Import Custom JavaScript === */
/* ================================ */

import Splide from '@splidejs/splide/dist/js/splide.min.js';
window.Splide = Splide;

require('./_front.js');
require('./_payment.js');
