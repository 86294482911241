let checkoutSubmit;

window.AdyenPayment = function (
    clientKey,
    adyenType,
    locale,
    environment,
    urls,
    urlParams = null,
    klarnaIcon,
    paymentMethods,
    paymentUrl,
    makePaymentUrl
) {
    startCheckout();

    // Typical checkout experience
    async function startCheckout() {
        // Used in the demo to know which type of checkout was chosen
        let checkout;
        try {
            const checkoutSessionResponse = await callServer(paymentUrl);
            checkout = await createAdyenCheckout(checkoutSessionResponse);
            checkoutSubmit = checkout.create(adyenType, {
                openFirstPaymentMethod: false,
                openFirstStoredPaymentMethod: false,
                onSelect: (data) => {
                    submitButtonAction(data);
                }
            }).mount(document.getElementById("payment"));

        } catch (error) {
            alert("Error occurred. Look at console for details");
        }
    }

    async function createAdyenCheckout(session) {
        return new AdyenCheckout(
            {
                clientKey,
                paymentMethodsResponse: paymentMethods,
                locale: locale,
                environment: environment,
                session: session,
                showPayButton: false,
                analytics: {
                    enabled: true
                },
                paymentMethodsConfiguration: {
                    card: {
                        hasHolderName: true,
                        holderNameRequired: true,
                    },
                    paypal: {
                        blockPayPalCreditButton: true,
                        blockPayPalPayLaterButton: true
                    },
                    klarna_account: {
                        icon: klarnaIcon
                    }
                },
                onSubmit: (state, component) => {
                    makePayment(state.data)
                        .then(response => {
                            if (response.action) {
                                // handleServerResponse(response, component);
                            } else {
                                let myToastEl = document.getElementById('adyen-toast');
                                // To show the error toast result to the shopper.
                                if (![false, 0, "", null, undefined, NaN].includes(response.error)) {

                                    myToastEl.style.display = "block";
                                    document.getElementById('adyen-toast-body').innerText = response.message;

                                } else if (response.resultCode === 'Refused') {
                                    // Your function to show the final result to the shopper.
                                    myToastEl.style.display = "block";
                                    document.getElementById('adyen-toast-body').innerText = response.resultCode + ':' + response.refusalReason;
                                } else {

                                    handleServerResponse(response, component);
                                }
                            }
                        })
                        .catch(error => {
                            throw Error(error);
                        });
                },
                onPaymentCompleted: (result, component) => {

                    handleServerResponse(result, component);
                },
                onError: (error, component) => {

                    handleServerResponse(error, component);
                },
            }
        );
    }

    /**
     * Function make server call for payment
     * @param data
     * @returns {Promise<any>}
     */
    function makePayment(data) {
        return callServer(makePaymentUrl, data);
    }

    /**
     * Function for button state for different provider
     * @param data
     */
    function submitButtonAction(data) {

        let paymentCodBtn = document.getElementById('payment-cod');
        let button = document.getElementById('submit-payment');

        if (paymentCodBtn) {
            paymentCodBtn.checked = false;
        }


        button.setAttribute('wire:click', 'storeOrder("' + data.props.type + '")');

        if (data.props.type === 'paypal') {
            data.props.showPayButton = true;
            button.style.display = "none";
        } else {
            button.style.display = "block";
        }
    }

    /**
     * Handle server response from adyen
     * @param res
     * @param component
     */
    function handleServerResponse(res, component) {
        if (res.action) {
            component.handleAction(res.action);
        } else {
            switch (res.resultCode) {
                case "Authorised":
                    window.location.href = urls[0];
                    break;
                case "Pending":
                case "Received":
                    window.location.href = urls[1];
                    break;
                case "Refused":
                    window.location.href = urls[2];
                    break;
                default:
                    window.location.href = urls[3];
                    break;
            }
        }
    }

    /**
     * Calls your server endpoints
     * @param url
     * @param data
     * @returns {Promise<any>}
     */
    async function callServer(url, data) {
        const res = await fetch(url, {
            method: "POST",
            body: data ? JSON.stringify(data) : "",
            headers: {
                "X-CSRF-TOKEN": document.querySelector('input[name="_token"]').value,
                "Content-Type": "application/json",
            },
        });
        return res.json();
    }
}

/**
 *Function for submit Adyen form if not PayPal or COD
 * @param payment
 */
window.addEventListener("adyenPayment", (e) => {
    try {
        checkoutSubmit.submit();
    } catch (error) {
        alert("Error occurred. Look at console for details");
    }
});

/**
 * Reset Adyen if is chosen COD for payment
 */
window.resetAdyen = function () {
    let button = document.getElementById('submit-payment');
    button.style.display = "block";
    button.setAttribute('wire:click', 'storeOrder("cod")');

    if (checkoutSubmit) {
        checkoutSubmit.closeActivePaymentMethod();
    }
}

/**
 * Close alert and reset adyen fields
 */
window.closeAdyenToast = function () {
    let ToastEl = document.getElementById('adyen-toast');
    ToastEl.style.display = "none";
    checkoutSubmit.setStatus('ready');
}
