
// window.onscroll = function () { scrollFunction() };
//
// function scrollFunction() {
//     if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
//         document.getElementByClassName(".nav-wrapper").style.height = "90px";
//     } else {
//         document.getElementByClassName(".nav-wrapper").style.height = "120px";
//     }
// }

$("#search-icon").click(function () {
    $(".nav").toggleClass("search");
    $(".nav").toggleClass("no-search");
    $(".search-input").toggleClass("search-active");
});

$('.menu-toggle').click(function () {
    $(".nav").toggleClass("mobile-nav");
    $(this).toggleClass("is-active");
});

// tabs
$(document).ready(function () {
    $('.tabs-nav a').click(function (event) {
        event.preventDefault();
        $(this).closest('.tabs-container').find('.tabs-nav li').removeClass('active');
        $(this).closest('li').addClass('active');
        var currentTab = $(this).attr('href');
        $(this).closest('.tabs-container').find('.tab-content div').removeClass('active');
        $(this).closest('.tabs-container').find(currentTab).addClass('active');
    });
});
